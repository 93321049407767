import React, { useRef } from "react";
import { graphql } from "gatsby";
import HandCraftedMeal from "../components/accommodation/handcrafted_meal";
import TranquilobayDifference from "../components/accommodation/how_tranquilobay_different";
import PanamaResort from "../components/accommodation/panamaresort";
import ScheduleVacation from "../components/accommodation/schedulevacation";
import VacationExperience from "../components/accommodation/vacationexperience";
import FeaturedList from "../components/common/featuredlist";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import Review from "../components/common/review";
import Layout from "../components/layouts";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import Seo from "../components/seo";
import SliderAndContent from "../components/common/SliderAndContent";
import useIsVisible from "../hooks/isVisible";
import Faqs from "../components/home/faq";

const Accommodations = ({
  data: { sanityAccommodationsPage, sanitySitesettings },
}) => {
  const elemRef = useRef();

  const isVisible = useIsVisible(elemRef);

  let seo;
  let hero;
  let breadcrumb;
  let features;
  let review;
  let guide;
  let bookVacation;
  let freshMeal;
  let panamaVacation;
  let yourFvt;
  let tranqiulobay;
  let faq;

  sanityAccommodationsPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "reviewSection") {
      review = element;
    } else if (element._type === "bookVacation") {
      bookVacation = element;
    } else if (element._type === "freshMeal") {
      freshMeal = element;
    } else if (element._type === "panamaVacation") {
      panamaVacation = element;
    } else if (element._type === "yourFvt") {
      yourFvt = element;
    } else if (element._type === "tranqiulobay") {
      tranqiulobay = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero heroSection={hero} />
      <Logo logo={sanitySitesettings?.logo} />
      {features && <FeaturedList data={features} />}
      {review && <Review data={review} />}
      {bookVacation && <VacationExperience data={bookVacation} />}
      <div ref={elemRef}>
        {isVisible && (
          <>
            {sanityAccommodationsPage?.contentslider?.map((item, index) => (
              <SliderAndContent
                data={item}
                key={index}
                sliderId={`accommodation-${index}`}
                show="show-up"
              />
            ))}

            {freshMeal && <HandCraftedMeal data={freshMeal} show="show-up" />}

            {panamaVacation && (
              <ScheduleVacation data={panamaVacation} show="show-up" />
            )}

            {yourFvt && (
              <PanamaResort
                data={yourFvt}
                minH="min-h-[60vh] sm:min-h-screen"
                bgUrl={`linear-gradient(to bottom,rgba(214, 214, 214, 0.90),rgba(192, 192, 192, 0.90)),url(${yourFvt?.sectionBG?.asset?.url})`}
                show="show-up"
              />
            )}
            {tranqiulobay && (
              <TranquilobayDifference data={tranqiulobay} show="show-up" />
            )}
            {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}
            {breadcrumb && <BreadCrumb path={breadcrumb} show="show-up" />}
            <Instagram />
            {guide && (
              <>
                <CompaniesGuided data={guide} show="show-up" />
                <Logo logo={sanitySitesettings?.logo} />
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Accommodations;

export const query = graphql`
  query {
    sanityAccommodationsPage {
      pageBuilder {
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }
        ... on SanityReviewSection {
          _type
          comment
          subHeading
          subHeading2
          heading
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
        }
        ... on SanityBookVacation {
          _type
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          sliderImages {
            heading
            subHeading
            sectionBg {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
        }
        ... on SanityFreshMeal {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          coverImg {
            alt
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
            }
          }
          foodItem {
            link
            svgs
            heading
            detailText
          }
        }
        ... on SanityPanamaVacation {
          _type
          desLink
          description
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          scheduleItem {
            heading
            number
            subHeading
            svg
            itemImg {
              alt
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
        }
        ... on SanityYourFvt {
          _type
          sectionBG {
            asset {
              url
            }
          }
          heading
          txt
          youtubeChannelId
          coverImg {
            alt
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
            }
          }
          vidoeItems {
            title
            videoId
          }
        }
        ... on SanityTranqiulobay {
          _type
          _rawContent
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          sliderItems {
            title
            content
            ctaButton1 {
              link
              title
              type
              variant
              formId
              downloadLink
            }
            slideBg {
              asset {
                url
              }
            }
          }
          title
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
      }
      contentslider {
        title
        bgRepeat
        bgSize
        direction
        height
        overlay
        size
        textColor
        paragraphTitle
        sectionBG {
          asset {
            url
          }
        }
        ctaButton1 {
          link
          title
          type
          variant
          formId
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
        }
        ctaButton3 {
          link
          title
          type
          variant
          formId
        }
        sliderImages {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
          }
        }
        _rawContent
        _rawContent2
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, formats: WEBP, placeholder: BLURRED)
        }
      }
    }
  }
`;

import { Link } from "gatsby";
import React from "react";
import Button from "../../common/buttons/Button";
import SecondaryCard from "../cards/SecondaryCard";

const ScheduleVacation = ({ data, show = "show", url = false }) => {
  return (
    <div
      className={`relative max-w-[1140px]  p-5 sm:p-5 w-full mx-auto min-h-[90vh] ${show}`}
    >
      <h1 className="text-3xl  sm:text-[40px] font-bold text-secondary pt-5 pb-[10px] text-center mb-3 uppercase">
        {data?.heading}
      </h1>

      {/* Cards Container */}

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 gap-y-5 pt-5 pb-10 max-w-full mx-auto">
        {data?.scheduleItem?.map((item, index) => {
          return <SecondaryCard item={item} key={index} url={url} />;
        })}
      </div>

      {/* Link */}

      <Link
        to={data?.desLink}
        className="block w-full pb-5 text-center text-primary text-xl font-bold tracking-[2px]"
      >
        {data?.description}
      </Link>

      {/* Buttons Container */}
      <div className="w-[95%] mx-auto  mb-[10px] flex justify-center">
        <div className="w-[300px] pt-5 pb-10 m-[1px]">
          <Button
            btnText={data?.ctaButton1?.title}
            btnLink={data?.ctaButton1?.link}
            btnType={data?.ctaButton1?.variant}
            linkType={data?.ctaButton1?.type}
            formId={data?.ctaButton1?.formId}
            downloadLink={data?.ctaButton1?.downloadLink}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleVacation;
